import { IKit } from './kit';
import { IAddress } from './address';
import { IAlbum } from './album';
import { IImage } from './image';
import { IReview } from './review';
import { ITheme } from './theme';
import { ISubscription } from './subscription';
import { INotification } from './notification';

export interface IUser {
  _id?: string;
  email: string;
  mobile: string;
  businessName: string;
  instaUsername: string;
  kit: IKit[];
  location: IAddress;
  hiddenImages?: IImage[];
  ignoredImages?: IImage[];

  albums?: IAlbum[];
  wizardStep?: EWizardStep;
  facebookPage?: string;
  profileImg?: string;
  slogan?: string;
  coverImgs?: string[];
  notifications?: INotification[];
  bio?: string;
  instagramAuth?: {
    token: string;
    tokenType: string;
    expiryDate: string;
  };
  images?: IImage[];
  totalImageCount?: number;
  reviews?: IReview[];
  avgReviewScore?: number;

  theme?: ITheme;

  subscriptions?: ISubscription[];
  website?: string;

  favIcon?: string;

  reviewCardTemplate?: string;
  imgAutoplay?: false;
  published?: boolean;
}

export enum EWizardStep {
  businessName = 'businessName',
  instaUsername = 'instaUsername',
  kit = 'kit',
  contact = 'contact',
  complete = 'complete',
}
