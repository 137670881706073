import React from 'react';
import { f7Context } from '.';
import AppRoutes from './routes/AppRoutes/AppRoutes';
import 'framework7/css/framework7.bundle.min.css';
import './styles/global-styles.scss';

class App extends React.Component {
  state = {
    f7: null,
  };

  componentDidMount() {
    this.$f7ready?.((f7) => {
      this.setState({ f7 });
    });
  }


  render() {

    return (
      <f7Context.Provider value={{ f7: this.state.f7 }}>
        <AppRoutes />
      </f7Context.Provider>
    );
  }
}

export default App;
