import * as React from 'react';
import { Page } from 'framework7-react';
import ProfileBanner from '../../components/ProfileBanner/ProfileBanner';
import About from '../../components/About/About';
import ProfileKit from '../../components/ProfileKit/ProfileKit';
import ProfileGalleryList from '../../components/ProfileGalleryList/ProfileGalleryList';
import ProfileReviews from '../../components/ProfileReviews/ProfileReviews';
import ProfileContact from '../../components/ProfileContact/ProfileContact';
import ProfileSocial from '../../components/ProfileSocial/ProfileSocial';
import AlbumView from '../../components/AlbumView/AlbumView';
import useToggle from '../../hooks/useToggle';
import { IAlbum } from '../../types/album';
import AllReviews from '../../components/AllReviews/AllReviews';
import useUserStore from '../../hooks/useUserStore';
import ProfileDesktopView from '../../components/ProfileDesktopView/ProfileDesktopView';
import ShowIf from '../../lib/ShowIf/ShowIf';
import { isMobile } from '../../utils';
import { DEFAULT_THEME } from '../../constants';
import ThemeLoader from '../../components/ThemeLoader/ThemeLoader';

interface IProfileProps {}
const Profile: React.FC<IProfileProps> = ({}) => {
  const { user } = useUserStore();
  const allReviews = useToggle();

  const [selectedAlbum, setSelectedAlbum] = React.useState<IAlbum>();

  React.useEffect(() => {
    const { primaryColor, heading, font } = DEFAULT_THEME;
    const styles = {
      '--user-theme-color-primary': user.theme?.primaryColor || primaryColor,
      '--user-theme-color-heading': user.theme?.heading.color || heading.color,
      '--user-theme-font-heading': user.theme?.heading.font || heading.font,
      '--user-theme-font-body': user.theme?.font || font,
      '--f7-theme-color': 'var(--user-theme-color-primary)',
      '--f7-button-text-color': 'var(--user-theme-color-primary)',
      '--f7-actions-button-text-color': 'var(--user-theme-color-primary)',
    } as React.CSSProperties;

    document.querySelector('body')?.setAttribute(
      'style',
      Object.keys(styles)
        .map((key) => `${key}: ${styles[key]}`)
        .join(';')
    );
  }, [user.theme]);

  return (
    <Page className="bg-white">
      <ThemeLoader
        themeId={user.theme?.id || ''}
        website={user.website || ''}
      />
    </Page>
    // <Page className="bg-white">
    //   <ShowIf condition={!user._id || !user.published}>
    //     <div className="fixed top-0 left-0 w-full h-full z-20 bg-white"></div>
    //   </ShowIf>

    //   <ShowIf condition={isMobile()} elseTemplate={<ProfileDesktopView />}>
    //     <div>
    //       <ProfileBanner
    //         businessName={user.businessName}
    //         profileImg={user.profileImg}
    //         coverImgs={user.coverImgs}
    //         galleryImages={user?.images}
    //         totalImageCount={user?.totalImageCount}
    //         slogan={user?.slogan}
    //       />

    //       <div className="p-5">
    //         <About about={user.bio} location={user.location} />
    //       </div>

    //       <hr className="border-t border-gray-100 mx-3" />

    //       <div className="p-5">
    //         <ProfileKit kit={user.kit} />
    //       </div>

    //       <hr className="border-t border-gray-100 mx-3" />
    //       <section className="p-5">
    //         <ProfileGalleryList
    //           albums={user.albums || []}
    //           totalImages={user?.totalImageCount}
    //           onAlbumSelect={setSelectedAlbum}
    //           hideViewAll
    //         />
    //       </section>

    //       <ShowIf condition={!!user.reviews?.length}>
    //         <hr className="border-t border-gray-100 mx-3" />

    //         <div className="p-5">
    //           <ProfileReviews
    //             reviewScore={user?.avgReviewScore || 0}
    //             reviewItems={user?.reviews
    //               ?.filter((r) => r.status === 'visible')
    //               .slice(0, 3)}
    //             reviewsCount={
    //               user?.reviews?.filter((r) => r.status === 'visible').length ||
    //               0
    //             }
    //             onViewaAll={allReviews.open}
    //           />
    //         </div>
    //       </ShowIf>

    //       <hr className="border-t border-gray-100 mx-3" />

    //       <div className="p-5">
    //         <ProfileContact />
    //       </div>

    //       <hr className="border-t border-gray-100 mx-3" />

    //       <div className="p-5">
    //         <ProfileSocial />
    //       </div>

    //       <div className="p-5">
    //         {/* <Button large fill raised>
    //       Instant Quote
    //     </Button> */}

    //         <div className="text-center text-gray-500">
    //           powered by <span className="underline">Nishi</span>
    //         </div>
    //       </div>
    //       <AlbumView
    //         album={selectedAlbum}
    //         open={!!selectedAlbum}
    //         onClose={() => setSelectedAlbum(undefined)}
    //       />

    //       <AllReviews
    //         open={allReviews.on}
    //         onClose={allReviews.close}
    //         reviewScore={user.avgReviewScore}
    //         initialReviews={user?.reviews?.filter(
    //           (r) => r.status === 'visible'
    //         )}
    //       />
    //     </div>
    //   </ShowIf>
    // </Page>
  );
};

export default Profile;
