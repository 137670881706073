import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './store';
import { BrowserRouter } from 'react-router-dom';

// Import F7 Bundle
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import F7-React Plugin
import Framework7React, { App as F7App, View } from 'framework7-react';

// Init F7-React Plugin
Framework7.use(Framework7React);

const f7Params = {
  name: 'Flayr Provider',
  id: 'com.provider.flayr',
  theme: 'ios',
  clicks: {
    externalLinks: '.external',
  },
};

export const f7Context = React.createContext<{ f7: any }>({
  f7: {},
});

ReactDOM.render(
  <React.StrictMode>
    <F7App params={f7Params}>
      <View>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </View>
    </F7App>
  </React.StrictMode>,
  document.getElementById('root')
);
