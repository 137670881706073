import Axios from 'axios';
import useLoaderStore from './useLoaderStore';
import { API_URL } from '../constants';
import { getAuthHeader } from '../utils';
// import cogoToast from 'cogo-toast';

function catchFn(
  error: any,
  options: IUseBackendApiOption,
  hideLoader: Function
) {
  console.error({ error });
  options.loader && hideLoader();
  const errorData = error?.response?.data || error;
  // options.showToastOnError && cogoToast.error(getErrorMessage(error));
  options.onError && options.onError(errorData);
  throw error;
}

function getErrorMessage(error: any) {
  if (typeof error?.response?.data === 'string') {
    return error?.response?.data;
  }
  if (typeof error.message === 'string') {
    return error.message;
  }
  return 'An error occured';
}

export interface IUseBackendApiOption {
  auth?: boolean;
  loader?: boolean;
  headers?: any;
  onError?: (e: any) => void;
  showToastOnError?: boolean;
  showToastOnSuccess?: boolean;
  successMsg?: string;
}
const defaultOptions: IUseBackendApiOption = {
  auth: true,
  loader: true,
  headers: {},
  onError: undefined,
  showToastOnError: false,
  showToastOnSuccess: false,
  successMsg: 'Success',
};
async function makeHttpCall(
  type: string,
  url: string,
  options: IUseBackendApiOption,
  dataOrParams: any,
  showLoader: Function,
  hideLoader: Function
) {
  try {
    options = { ...defaultOptions, ...options };
    options.loader && showLoader();
    let headers = options.headers || {};
    if (options.auth) {
      headers = getAuthHeader(headers);
    }
    url = `${API_URL}${url}`;
    let res: any = { data: null };
    switch (type) {
      case 'get':
        res = await Axios.get(url, { params: dataOrParams, ...headers });
        break;
      case 'post':
        res = await Axios.post(url, dataOrParams, headers);
        break;
      case 'patch':
        res = await Axios.patch(url, dataOrParams, headers);
        break;
      case 'del':
        res = await Axios.delete(url, headers);
        break;
    }
    options.loader && hideLoader();
    // options.showToastOnSuccess && cogoToast.success(options.successMsg);
    return res.data;
  } catch (err) {
    return catchFn(err, options, hideLoader);
  }
}

export default function useBackendApi() {
  const { showLoader, hideLoader } = useLoaderStore();

  async function get(
    url: string,
    params: any = {},
    options: IUseBackendApiOption = {
      auth: true,
      loader: true,
      headers: {},
      onError: undefined,
      showToastOnError: false,
    }
  ) {
    return makeHttpCall('get', url, options, params, showLoader, hideLoader);
  }

  async function post(
    url: string,
    data: any = {},
    options: IUseBackendApiOption = {
      auth: true,
      loader: true,
      headers: {},
      onError: undefined,
      showToastOnError: false,
    }
  ) {
    return makeHttpCall('post', url, options, data, showLoader, hideLoader);
  }

  async function patch(
    url: string,
    data: any = {},
    options: IUseBackendApiOption = {
      auth: true,
      loader: true,
      headers: {},
      onError: undefined,
      showToastOnError: false,
    }
  ) {
    return makeHttpCall('patch', url, options, data, showLoader, hideLoader);
  }

  async function del(
    url: string,
    options: IUseBackendApiOption = {
      auth: true,
      loader: true,
      headers: {},
      onError: undefined,
      showToastOnError: false,
    }
  ) {
    return makeHttpCall('del', url, options, null, showLoader, hideLoader);
  }

  return { get, post, patch, del };
}
