import React from 'react';
import useUserStore from '../../hooks/useUserStore';
import { useHistory } from 'react-router';

interface ComingSoonProps {}

const ComingSoon: React.FunctionComponent<ComingSoonProps> = ({}) => {
  const { user } = useUserStore();
  const history = useHistory();

  React.useEffect(() => {
    if (user.published) {
      history.push('/');
    }
  }, [user]);

  return (
    <div
      className="flex-center fixed top-0 left-0 w-full h-full text-white"
      style={{
        background:
          'linear-gradient(-9deg, #6a82fb 0%, #6a82fb 40%, #fc5c7d 100%)',
      }}
    >
      <h1 style={{ fontSize: '80px' }} className="text-center">
        Coming Soon
      </h1>
    </div>
  );
};
export default ComingSoon;
