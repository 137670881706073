import React, { useEffect, useContext } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import Profile from '../../pages/Profile/Profile';
import { getWebsiteUrl } from '../../utils';
import ComingSoon from '../../pages/ComingSoon/ComingSoon';
import { f7Context } from '../..';
import useLoaderStore from '../../hooks/useLoaderStore';
import useUserStore from '../../hooks/useUserStore';
import useBackendApi from '../../hooks/useBackendApi';
import { Helmet } from 'react-helmet';
import Favicon from 'react-favicon';

interface AppRoutesProps { }

const AppRoutes: React.FunctionComponent<AppRoutesProps> = ({ }) => {
  const { f7 } = useContext(f7Context);
  const { loader } = useLoaderStore();
  const { user, setUser } = useUserStore();
  const { get } = useBackendApi();
  const history = useHistory();

  React.useEffect(() => {
    if (f7) {
      if (loader.showing) {
        f7.preloader.show();
      } else {
        f7.preloader.hide();
      }
    }
  }, [loader]);

  React.useEffect(() => {
    const url = getWebsiteUrl();
    if (url) {
      init(url);
    }
  }, []);

  async function init(url) {
    const userData = await get(
      `/v1/user/website?url=${encodeURIComponent(url)}`
    );
    setUser(userData);
    const reviewData = await await get(
      `/v1/user/website-reviews/${userData._id}`, {}, { loader: false }
    );
    setUser({ reviews: reviewData.reviews, avgReviewScore: reviewData.avgReviewScore })
    if (!userData.published) {
      history.push('/coming-soon');
    }
  }

  return (
    <>
      <Favicon
        url={[
          user.profileImg ||
          'https://s3-ap-southeast-2.amazonaws.com/flayr-landing-page/img/favicon.ico',
        ]}
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{user.businessName || ''}</title>
      </Helmet>
      <Switch>
        <Route exact path="/" component={Profile} />
        <Route exact path="/coming-soon" component={ComingSoon} />
      </Switch>
    </>
  );
};
export default AppRoutes;
