import { useDispatch, useSelector } from 'react-redux';
import { IAppStore } from '../types/appStore';
import { setUserAction } from '../actions/user';
import { IUser, EWizardStep } from '../types/user';

export default function useUserStore() {
  const dispatch = useDispatch();

  const user: IUser = useSelector((store: IAppStore) => store.user);

  const setUser = (payload: IUser | any) => dispatch(setUserAction(payload));

  const resetUser = () =>
    dispatch(
      setUserAction({
        _id: '',
        email: '',
        businessName: '',
        instaUsername: '',
        kit: [],
        location: {
          city: '',
          formatted: '',
        },
        mobile: '',
        wizardStep: EWizardStep.complete,
        facebookPage: '',
        slogan: '',
      })
    );

  return {
    user,
    setUser,
    resetUser,
  };
}
