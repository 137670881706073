
import { combineReducers } from 'redux';
import { IAppStore } from '../types/appStore';
import { loaderReducer } from './loader';
import userReducer from './user.reducer';

const rootReducer = combineReducers<IAppStore>({
  loader: loaderReducer,
  user: userReducer
});

export default rootReducer;
