import Axios from 'axios';
import { API_URL } from './constants';
import { IAlbum } from './types/album';
import { ISubscription } from './types/subscription';

export function getUserDataFromStorage(): any {
  const item = localStorage.getItem('flayr_user_data') || '{}';
  if (item) {
    return JSON.parse(item) || '';
  }
  return {};
}

export function setUserDataToStorage(item: any = {}): any {
  let currentItem: any = localStorage.getItem('flayr_user_data');
  currentItem = currentItem ? JSON.parse(currentItem) : {};
  const newItem = { ...currentItem, ...item };
  return localStorage.setItem('flayr_user_data', JSON.stringify(newItem));
}

export function clearUserDataFromStorage(): any {
  return localStorage.setItem('flayr_user_data', '{}');
}

// export function isTokenExpired(token: string) {
//   if (token) {
//     const decoded: { exp: number } = jwt_decode(token);
//     const now = Date.now().valueOf() / 1000;
//     if (!(typeof decoded.exp !== 'undefined' && decoded.exp < now)) {
//       // not expired
//       return false;
//     }
//   }
//   return true;
// }

export function getAuthHeader(
  header = {}
): { headers: { Authorization?: string; [other: string]: any } } | {} {
  const user = getUserDataFromStorage();

  if (user && user.token) {
    return {
      headers: {
        ...{ 'Content-Type': 'application/json' },
        ...header,
        Authorization: 'Bearer ' + user.token,
      },
    };
  } else {
    return { headers: { ...header } };
  }
}

export function resizeImage(size: string, url: string) {
  if (!url) {
    return '';
  }
  if (url.match('flayr-big-collection.s3-ap-southeast-2.amazonaws.com')) {
    let parts = url.split('.com/');
    let imageName = parts[1];
    let newUrl = `https://d3q28m8ggnu326.cloudfront.net/${size}/${encodeURIComponent(
      encodeURIComponent(imageName)
    )}`;
    return newUrl;
  } else {
    return url;
  }
}

// export function getTokenData() {
//   return jwt_decode(getUserDataFromStorage().token);
// }

export async function uploadSingleImage(image: File, config = {}) {
  const data: any = new FormData();
  const header = {
    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
  };
  data.append('images', image);
  return Axios.post(`${API_URL}/v1/image/upload-single-image`, data, {
    ...config,
    ...getAuthHeader(header),
  });
}
// export function findAlbumByName(name: string, albums?: IAlbum[]) {
//   if (!albums) return undefined;
//   const album = find(albums, { name });
//   return album;
// }

export function fileToDataURI(file: File) {
  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader();
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}

export function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export function selectDeselectItem(
  newSelection: any,
  collection: any[],
  field = '_id'
) {
  if (typeof newSelection === 'string') {
    if (collection.includes(newSelection)) {
      return [...collection.filter((item) => item !== newSelection)];
    } else {
      return [...collection, newSelection];
    }
  }
  const existing = collection.indexOf(newSelection) !== -1;
  if (existing) {
    return [
      ...collection.filter((item) => item[field] !== newSelection[field]),
    ];
  } else {
    return [...collection, newSelection];
  }
}

function getDeviceType() {
  var check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window['opera']);
  return check ? 'mobile' : 'desktop';
}

function getOperatingSystem() {
  if (getDeviceType() === 'desktop') {
    let OSName = 'Unknown OS';
    if (navigator.appVersion.indexOf('Win') != -1) OSName = 'Windows';
    if (navigator.appVersion.indexOf('Mac') != -1) OSName = 'MacOS';
    if (navigator.appVersion.indexOf('X11') != -1) OSName = 'UNIX';
    if (navigator.appVersion.indexOf('Linux') != -1) OSName = 'Linux';
    return OSName;
  } else {
    let userAgent: any =
      navigator.userAgent || navigator.vendor || window['opera'];
    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPod/i)
    ) {
      return 'iOS';
    } else if (userAgent.match(/Android/i)) {
      return 'Android';
    } else {
      return 'unknown';
    }
  }
}

export function formatSmsLinkForDevice(num: string, body: string): string {
  const OS = getOperatingSystem();
  if (OS === 'MacOS' || OS === 'iOS') {
    return `sms://${num}/&body=${encodeURI(body)}`;
  }
  return `sms://${num}/?body=${encodeURI(body)}`;
}

export function isProSubscribed(subscriptions?: ISubscription[]) {
  if (!subscriptions) {
    return false;
  }
  return subscriptions.some((sub) => sub.type === 'pro');
}

export function generateFavIcon(
  src: string,
  returnType: 'url' | 'blob' = 'url'
) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const img = new Image();
  img.src = src;
  img.onload = () => {
    context?.drawImage(img, 0, 0, 64, 64);
  };
  const icoUrl = canvas.toDataURL();
  if (returnType === 'url') {
    return icoUrl;
  }
  return dataURItoBlob(icoUrl);
}

export function getWebsiteUrl() {
  const path = window.location.href;
  let website = path.match(
    /^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/?\n]+)/
  )?.[0];
  if (/localhost/.test(website || '')) {
    website = 'https://pj-bakes.nishi.app';
  }
  return website;
}

export function isMobile() {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  );
}
