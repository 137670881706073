
import { SET_LOADER } from './action-types';
import { ILoader } from '../types/loader';

export function createSetLoadingAction(payload: ILoader) {
  return {
    type: SET_LOADER,
    payload
  };
}
